import { useRouter } from 'next/router';
import { ToastContainer as Toast, ToastOptions, Zoom } from 'react-toastify';
import styles from '../../../styles/toast/toast.module.scss';

const toastOptions: ToastOptions = {
  position: 'bottom-center',
  autoClose: 1000,
  className: styles.root,
  closeButton: false,
  transition: Zoom,
  hideProgressBar: true,
};

const aroundOption: ToastOptions = {
  position: 'bottom-center',
  autoClose: 1500,
  className: styles.aroundMe,
  closeButton: false,
  transition: Zoom,
  hideProgressBar: true,
};

export const ToastContainer = () => {
  const router = useRouter();
  const { pathname } = router;
  const option = pathname === '/around-me' ? aroundOption : toastOptions;

  return <Toast {...option} />;
};
