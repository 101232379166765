import Head from 'next/head';
import { useRouter } from 'next/router';

type Props = {
  title?: string;
  imgUrl?: string;
  currentUrl?: string;
};
export default function Metadata({ title, imgUrl, currentUrl }: Props) {
  const { asPath } = useRouter();
  const TITLE = title ? `${title} | 그라운드` : '그라운드';
  const DESC = '그라운드ㅣ숨겨진 재미, 농어촌 여행';

  return (
    <Head>
      <title>{TITLE}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0,viewport-fit=cover"
        charSet="utf-8"
      />
      <meta name="description" content={DESC} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="그라운드" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={DESC} />
      <meta property="og:url" content={`${process.env.NEXT_PUBLIC_APP_API}${asPath}`} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="400" />
      {imgUrl ? <meta property="og:image" content={imgUrl} /> : null}
      <script defer src="https://developers.kakao.com/sdk/js/kakao.min.js"></script>
    </Head>
  );
}
